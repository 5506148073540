import { call, put } from 'redux-saga/effects';

import api from 'api';

import { notifyError } from 'store/notifications/actions';
import { setGuides, setLoadingState } from 'store/guides/actions';

import { IGuide } from 'pages/guides/types';

export function* getGuides() {
    try {
        const response: IGuide[] = yield call(api.user.getProducts);

        yield put(setGuides(response));
        yield put(setLoadingState(false));
    } catch (error) {
        notifyError({ message: 'getGuides error' });
    }
}
