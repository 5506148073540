import { useEffect, useMemo, useState } from 'react';

import { Experiment } from '../utils/experiments';
import { STAGE_EXPERIMENT_KEY } from '../utils/const';

import { isProd } from 'helpers/utils';
import { getAbAutoTestsParams } from '../helpers';

import { getString } from 'firebase/remote-config';

import { activationConfigPromise, remoteConfig } from '../initFirebase/initFirebase';

const useVariant = (key: Experiment | string) => {
    const [variant, setVariant] = useState('');

    const { variantName } = useMemo(() => getAbAutoTestsParams(), []);

    useEffect(() => {
        // needed to set variant name for auto tests
        if (variantName) {
            setVariant(variantName);
        } else {
            activationConfigPromise.then(() => {
                const envBasedKey = isProd() ? key : `${key}_${STAGE_EXPERIMENT_KEY}`;

                const remoteVariant = getString(remoteConfig, envBasedKey);

                setVariant(remoteVariant);
            });
        }
    }, [key, variantName]);

    return variant;
};

export default useVariant;
