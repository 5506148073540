import { PersonalPlanPropsType } from 'containers/Workouts/PersonalPlan/types';

import { WorkoutState } from 'types/workout';
import { IWorkoutExtended, IPersonalPlanDay } from 'types/personalPlan';

import dayjs from 'dayjs';

const userData = dayjs();

type IAddWorkoutStatus = ({
    days,
    currentDay,
}: {
    days: IPersonalPlanDay[];
    currentDay: PersonalPlanPropsType['currentDay'];
}) => {
    workouts: IWorkoutExtended[];
    availableCurrentDay: PersonalPlanPropsType['currentDay'];
};

export const addWorkoutStatus: IAddWorkoutStatus = ({ days, currentDay }) => {
    let availableCurrentDay = currentDay;

    const workoutsWithStatus = days.map(({ workouts, day_number }) => {
        const [workout] = workouts;
        const prevWorkoutDay = currentDay - 1;
        const prevWorkout = days.find((item) => item.day_number === prevWorkoutDay);
        const isPrevWorkoutWasToday = prevWorkout?.workouts[0].completion_date
            ? userData.isSame(prevWorkout?.workouts[0].completion_date, 'day')
            : false;

        const isAvailableToday = day_number === currentDay && !isPrevWorkoutWasToday;
        const isNotAvailableToday = (day_number === currentDay && isPrevWorkoutWasToday) || day_number > currentDay;
        const isCompletedToday = day_number === prevWorkout?.day_number;

        if (isAvailableToday) {
            availableCurrentDay = day_number;

            return { ...workout, completeness: WorkoutState.availableToday, day_number };
        }
        if (isNotAvailableToday) {
            return { ...workout, completeness: WorkoutState.notAvailableToday, day_number };
        }
        if (isCompletedToday) {
            availableCurrentDay = isPrevWorkoutWasToday ? day_number : currentDay;

            return {
                ...workout,
                completeness: isPrevWorkoutWasToday ? WorkoutState.completedToday : WorkoutState.completedBeforeToday,
                day_number,
            };
        }

        return { ...workout, completeness: WorkoutState.completedBeforeToday, day_number };
    });

    return {
        workouts: workoutsWithStatus,
        availableCurrentDay,
    };
};
