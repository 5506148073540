import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getCurrentPlanSuccess, setCurrentPlanCompletion } from 'store/personalPlan/actions';

import { isWholePlanCompleted } from 'helpers/workout';
import { addWorkoutStatus } from 'helpers/personalPlan';

import { IPersonalPlanResponse } from 'types/personalPlan';

import { getWorkout } from './getWorkout';

export function* getCurrentPlan() {
    try {
        const { id, current_day, days, yoga_type, plan_type }: IPersonalPlanResponse = yield call(
            api.personalPlan.getCurrentPersonalPlan
        );

        const { workouts, availableCurrentDay } = addWorkoutStatus({ days, currentDay: current_day });

        yield put(
            getCurrentPlanSuccess({ id, current_day: availableCurrentDay, days: workouts, yoga_type, plan_type })
        );

        if (isWholePlanCompleted(workouts)) yield put(setCurrentPlanCompletion(true));

        yield call(getWorkout);
    } catch (error: any) {
        console.error('Get personal plan error: ', error.message);
    }
}
