import { call, put, select, take } from 'redux-saga/effects';

import api from 'api';

import { UPDATE_USER_ERROR, UPDATE_USER_SUCCESS } from 'store/user/actionTypes';
import { updateUserRequest } from 'store/user/actions';
import { generatePlanRequest, getCurrentPlanSuccess, setCurrentPlanCompletion } from 'store/personalPlan/actions';

import { FitnessLevel } from 'constants/user';
import { PlanType } from 'constants/personalPlan';

import { addWorkoutStatus } from 'helpers/personalPlan';

import { IPersonalPlanResponse } from 'types/personalPlan';

import { getWorkout } from './getWorkout';
import { selectPlanLevel, selectPlanType } from '../selectors';

export function* generatePlan({ payload }: ReturnType<typeof generatePlanRequest>) {
    try {
        yield put(setCurrentPlanCompletion(false));

        const planType: PlanType = yield select(selectPlanType);
        const planLevel: FitnessLevel = yield select(selectPlanLevel);

        if (payload.level && payload.level !== planLevel) {
            yield put(updateUserRequest({ payload: { fitness_level: payload.level } }));

            yield take([UPDATE_USER_SUCCESS, UPDATE_USER_ERROR]);
        }

        const { id, current_day, days, yoga_type, plan_type }: IPersonalPlanResponse = yield call(
            api.personalPlan.generatePersonalPlan,
            { plan_type: planType }
        );

        const { workouts, availableCurrentDay } = addWorkoutStatus({ days, currentDay: current_day });

        yield put(
            getCurrentPlanSuccess({ id, current_day: availableCurrentDay, days: workouts, yoga_type, plan_type })
        );

        yield call(getWorkout);
    } catch (error: any) {
        console.error('Generate personal plan error: ', error.message);
    }
}
