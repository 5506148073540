import { call, delay } from 'redux-saga/effects';

import api from 'api';

import { IFetchDiscountSubscription } from 'types/subscription';

export function* sendDiscountStatus(payload: IFetchDiscountSubscription) {
    const retries = 10;
    const delayDuration = 5000;

    for (let i = 0; i < retries; i++) {
        try {
            yield call(api.subscriptions.sendDiscountStatus, payload);

            return;
        } catch (error: any) {
            if (error?.response?.status === 409 && i < retries - 1) {
                yield delay(delayDuration);
            } else {
                console.error(error);
            }
        }
    }
}
