import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

export const BodyContainer = styled.div<{ isBlack?: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${({ isBlack }) =>
        isBlack &&
        css`
            background-color: ${color('on-primary')};
        `} }
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
`;
