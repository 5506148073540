import {
    GeneratePlanTypes,
    ISetLastPlanDayHatch,
    PersonalPlanState,
    WorkoutCompleteTypes,
} from 'store/personalPlan/types';

import * as actionTypes from './actionTypes';

import {
    IPersonalPlanResponse,
    IStatisticPersonalPlanResponse,
    IWorkoutExtended,
    IWorkoutResponse,
} from 'types/personalPlan';
import { Action } from 'types/commonInterfaces';

export const getCompletedPlanStatisticsRequest = (payload?: Action) => {
    return {
        type: actionTypes.GET_COMPLETED_PLAN_STATISTICS,
        payload,
    } as const;
};

export const getCompletedPlanStatisticsSuccess = (payload: IStatisticPersonalPlanResponse) => {
    return {
        type: actionTypes.GET_COMPLETED_PLAN_STATISTICS_SUCCESS,
        payload,
    } as const;
};

export const getWorkoutSuccess = (payload: IWorkoutResponse) => {
    return {
        type: actionTypes.GET_WORKOUT_SUCCESS,
        payload,
    } as const;
};

export const getCurrentPlanRequest = (payload?: Action) => {
    return {
        type: actionTypes.GET_CURRENT_PLAN,
        payload,
    } as const;
};

export const getCurrentPlanSuccess = (
    payload: Pick<IPersonalPlanResponse, 'id' | 'current_day' | 'yoga_type' | 'plan_type'> & {
        days: IWorkoutExtended[];
    }
) => {
    return {
        type: actionTypes.GET_CURRENT_PLAN_SUCCESS,
        payload,
    } as const;
};

export const generatePlanRequest = (payload: GeneratePlanTypes = {}) => {
    return {
        type: actionTypes.GENERATE_PLAN,
        payload,
    } as const;
};

export const setCurrentPlanCompletion = (payload: PersonalPlanState['isCurrentPlanComplete']) => {
    return {
        type: actionTypes.SET_CURRENT_PLAN_COMPLETION,
        payload,
    } as const;
};

export const workoutComplete = (payload: WorkoutCompleteTypes) => {
    return {
        type: actionTypes.COMPLETE_WORKOUT,
        payload,
    } as const;
};

export const setLastPlanDayHatch = (payload: ISetLastPlanDayHatch) => {
    return {
        type: actionTypes.SET_LAST_PLAN_DAY_HATCH,
        payload,
    } as const;
};

export const setCompletePlanModalVisibility = (payload: boolean) => {
    return {
        type: actionTypes.SET_COMPLETE_PLAN_MODAL_VISIBILITY,
        payload,
    } as const;
};

export const resetWorkout = () => {
    return {
        type: actionTypes.RESET_WORKOUT,
    } as const;
};
