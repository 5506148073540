import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { createElement, ElementType } from 'react';

import { PUBLIC, PRIVATE, COMPANION_MODE } from 'router/routes';
import RootLayout from 'router/layouts/RootLayout';
import CabinetLayout from 'router/layouts/CabinetLayout';
import AuthLayout from 'router/layouts/AuthLayout';

import lazyWithRetry from 'helpers/lazyWithRetry';
import { overriddenNavigate } from './routes/helpers';

import ErrorBoundary from 'components/ErrorBoundary';

import { RoutesType, LayoutProps } from './types';

import CompanionModeLayout from './layouts/CompanionModeLayout';

const createRoutes = (routes: RoutesType[], layout: ElementType<LayoutProps>) =>
    routes.map(({ path, titleKey, pathToFile }: RoutesType) => {
        const Component = lazyWithRetry(() => import(`../pages/${pathToFile}/index.tsx`));

        return <Route key={path} path={path} element={createElement(layout, { titleKey }, <Component />)} />;
    });

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />} errorElement={<ErrorBoundary />}>
            {createRoutes(Object.values(PUBLIC), AuthLayout)}
            {createRoutes(Object.values(PRIVATE), CabinetLayout)}
            {createRoutes(Object.values(COMPANION_MODE), CompanionModeLayout)}
        </Route>
    )
);

router.navigate = overriddenNavigate(router.navigate);

export default router;
