import * as action from './actions';

import * as actionTypes from './actionTypes';

import { IGuide } from 'pages/guides/types';

import { InferValueTypes } from 'types/commonTypes';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

interface GuidesState {
    userGuides: IGuide[];
    isLoading: boolean;
}

export const initialState = {
    userGuides: [],
    isLoading: true,
};

const guidesReducer = (state: GuidesState = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_LOADING_STATE:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_GUIDES:
            return { isLoading: false, userGuides: action.payload };

        default:
            return state;
    }
};

export default guidesReducer;
