import { ALL_PROBLEM_ZONES, ProblemZone } from 'constants/user';

import { UserResponse } from 'types/apiInterfaces/user';

const userAPIResponseTransformer = <T extends { problem_zones: ProblemZone[] }>(userData: T | UserResponse) => ({
    ...userData,
    problem_zones:
        !userData?.problem_zones?.length || userData?.problem_zones?.includes(ALL_PROBLEM_ZONES as ProblemZone)
            ? Object.values(ProblemZone)
            : userData.problem_zones,
});

export { userAPIResponseTransformer };
