import * as actionTypes from 'store/user/actionTypes';

import { SagaActionsProps } from 'types/commonInterfaces';
import { UserFeature, UserResponse } from 'types/apiInterfaces/user';
import { BaseSendEmailRequest, UpdateUserRequest, UserStore } from './types';

export const getCurrentUserRequest = (payload?: SagaActionsProps) => {
    return {
        type: actionTypes.GET_CURRENT_USER_REQUEST,
        payload,
    } as const;
};

export const getCurrentUserSuccess = (payload: UserResponse) => {
    return {
        type: actionTypes.GET_CURRENT_USER_SUCCESS,
        payload,
    } as const;
};

export const updateUserEmail = (payload: BaseSendEmailRequest) => {
    return {
        type: actionTypes.UPDATE_USER_EMAIL,
        payload,
    } as const;
};

export const updateUserRequest = (payload: UpdateUserRequest) => {
    return {
        type: actionTypes.UPDATE_USER_REQUEST,
        payload,
    } as const;
};

export const updateUserSuccess = (payload: UserResponse) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload,
    } as const;
};

export const updateUserError = (payload: UserStore['user_error']) => {
    return {
        type: actionTypes.UPDATE_USER_ERROR,
        payload,
    } as const;
};

export const resetUserData = () => {
    return {
        type: actionTypes.RESET_USER_DATA,
    } as const;
};

export const getUserFeatures = () => {
    return {
        type: actionTypes.GET_USER_FEATURES,
    } as const;
};

export const setUserFeatures = (payload: UserFeature[]) => {
    return {
        type: actionTypes.SET_USER_FEATURES,
        payload,
    } as const;
};
