import { store } from 'store/configureStore';

import { ActiveGroup, Experiment } from './experiments';
import { NEW_USER_DATE } from './const';

export const modifyCancelFlowExperimentName = (variant: string) => {
    const state = store.getState();

    const { first_paid_order_created_at } = state.user;

    if (!first_paid_order_created_at) return ActiveGroup.Default;

    const firstPurchaseDate = new Date(first_paid_order_created_at);
    const experimentStartDate = new Date(NEW_USER_DATE);

    return firstPurchaseDate >= experimentStartDate ? variant : ActiveGroup.Default;
};

const modificators: Partial<{ [key in Experiment]: (value: any) => any }> = {};

export default modificators;
