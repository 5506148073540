import ApiClient from 'services/ApiClient';

import {
    IPersonalPlanResponse,
    IPersonalPlanForDay,
    IStatisticPersonalPlanResponse,
    IWorkoutQueryParams,
    IWorkoutResponse,
} from 'types/personalPlan';

class PersonalPlanApi {
    getCurrentPersonalPlan = async <Response = IPersonalPlanResponse>(): Promise<Partial<Response> | unknown> =>
        await ApiClient.get<Response>('workout-service/v2/plan');

    generatePersonalPlan = async <Data = object>(payload: Data): Promise<Partial<Response> | unknown> => {
        return await ApiClient.post<Data>('workout-service/v2/plan', payload, {
            headers: {
                'client-version': '1.0.0',
            },
        });
    };

    getStatisticPersonalPlan = async <Data = number, Response = IStatisticPersonalPlanResponse>(
        id: Data
    ): Promise<Partial<Response> | unknown> =>
        await ApiClient.get<Response>(`workout-service/user/plan/${id}/statistics`);

    personalPlanForDay = async <Data extends IPersonalPlanForDay>(payload: Data): Promise<unknown> => {
        const { id, number, fact_duration, fact_kcal } = payload;

        return await ApiClient.post<Pick<IPersonalPlanForDay, 'fact_kcal' | 'fact_duration'>>(
            `workout-service/user/plan/${id}/day/${number}`,
            {
                fact_duration,
                fact_kcal,
            }
        );
    };

    getWorkout = async ({
        workout_duration,
        body_parts,
        focus,
        level,
        savasana_duration,
        warm_up_enabled,
        yoga_type,
        birthdate,
        bmi,
    }: IWorkoutQueryParams): Promise<Partial<IWorkoutResponse> | unknown> =>
        await ApiClient.get<IWorkoutQueryParams>(
            `workout-service/workout-builder/yoga/workout?workout_duration=${workout_duration}&body_parts=${body_parts}&focus=${focus}&level=${level}&savasana_duration=${savasana_duration}&warm_up_enabled=${warm_up_enabled}&yoga_type=${yoga_type}&birthdate=${birthdate}&bmi=${bmi}`
        );
}

export default PersonalPlanApi;
