import styled from 'styled-components';

import { color } from 'theme/selectors';

export const HeaderContainer = styled.header`
    min-height: 56px;
    flex-shrink: 0;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 16px;
    border-bottom: 1px solid ${color('border-subdued')};
`;

export const HeaderPublicContainer = styled(HeaderContainer)`
    border: none;
    filter: none;
`;

export const AvatarImage = styled.img`
    cursor: pointer;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    max-width: 50%;
`;
