import { GA_USER_ID } from 'constants/user';

import ApiClientMobile from 'services/ApiClientMobile';
import ApiClient from 'services/ApiClient';

import { getCookie } from 'helpers/cookie';

import {
    DeepLinkData,
    SignInData,
    UserInfoResponse,
    HashedEmailResp,
    UserFeature,
    SendTemporaryPasswordData,
    SendTemporaryPasswordResponse,
    UserResponse,
    UpdateUserRequest,
} from 'types/apiInterfaces/user';

class UserApi {
    getUser = async <Response = UserResponse>(): Promise<Partial<Response>> => {
        const gaId = getCookie(GA_USER_ID);

        return await ApiClient.get<Response>(
            'user/',
            {},
            {
                headers: {
                    ...(gaId && { 'google-analytics-id': gaId }),
                },
            }
        );
    };

    update = async <Data = UpdateUserRequest, Response = UserResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.patch<Data, Response>('user/', payload);
    };

    signIn = async <Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('sign-in', payload);
    };

    sendTemporaryPassword = async <Data = SendTemporaryPasswordData, Response = SendTemporaryPasswordResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClientMobile.post<Data, Response>('auth/send-temporary-password', payload);
    };

    deepLinks = async <Response = DeepLinkData>(): Promise<Partial<Response>> => {
        return await ApiClient.get<Response>('user/auth-deeplink');
    };

    getUserBySignature = async <Response = UserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        return await ApiClient.get<Response>(`core/user/${params.uId}/${params.signature}`);
    };

    hashedEmail = async () => {
        return await ApiClient.get<HashedEmailResp>('front-hash');
    };

    getProducts = async () => {
        return await ApiClient.get<HashedEmailResp>('user/products');
    };

    getFeatures = async () => {
        return await ApiClient.get<{ features: UserFeature[] }>('workout-service/subscriptions/v1/user/features');
    };

    getChannelDeepLink = async <Response = DeepLinkData>(): Promise<Partial<Response>> => {
        return await ApiClient.post<NonNullable<unknown>, Response>('user/big-screen/channel-deeplink', {});
    };
}

export default UserApi;
