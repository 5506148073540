import { Provider } from 'wikr-core-analytics';
import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getCurrentUserRequest, getCurrentUserSuccess } from 'store/user/actions';
import { notifyError } from 'store/notifications/actions';

import { userAPIResponseTransformer } from 'helpers/api';

import { UserResponse } from 'types/apiInterfaces/user';

export function* getCurrentUser({ payload }: ReturnType<typeof getCurrentUserRequest>) {
    const { onSuccess, onError } = payload ?? {};

    try {
        const userData: UserResponse = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        Provider.setUserId(userData.user_id);

        if (onSuccess) yield call(onSuccess, userData);
    } catch (error: any) {
        yield put(notifyError({ message: 'message.error.somethingWentWrong' }));
        if (onError) yield call(onError, error);
    }
}
