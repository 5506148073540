import { call, put, select } from 'redux-saga/effects';

import api from 'api';

import { UserStore } from 'store/user/types';
import { selectCurrentUser } from 'store/user/selectors';
import { getWorkoutQueryParams } from 'store/personalPlan/helpers';
import { getWorkoutSuccess } from 'store/personalPlan/actions';

import { YogaType } from 'constants/personalPlan';

import { IWorkout, IWorkoutResponse } from 'types/personalPlan';

import { selectCurrentDay, selectPlanDays, selectYogaType } from '../selectors';

export function* getWorkout() {
    try {
        const yogaType: YogaType = yield select(selectYogaType);
        const currentDay: number = yield select(selectCurrentDay);
        const days: IWorkout[] = yield select(selectPlanDays);
        const user: UserStore = yield select(selectCurrentUser);

        const workoutParams = getWorkoutQueryParams({
            currentDay: days[currentDay - 1],
            user,
            yoga_type: yogaType,
        });

        const workout: IWorkoutResponse = yield call(api.personalPlan.getWorkout, workoutParams);

        yield put(getWorkoutSuccess(workout));
    } catch (error: any) {
        console.error('Get personal plan error: ', error.message);
    }
}
