export const getCustomProperties = (inlineStyles: { [propertyName: string]: string }) =>
    Object.entries(inlineStyles)
        .map(([key, value]) => `${key}: ${value}`)
        .join(';');

export const withAlpha = (hex: string, opacity: number) => `${hex}${Math.floor(opacity * 255).toString(16)}`;

export const responsiveSize = (min: number, max: number, minWidth = 1240, maxWidth = 5120) =>
    `clamp(${min}px, ${(100 * (max - min)) / (maxWidth - min)}vw + ${
        min - (minWidth * (max - min)) / (maxWidth - min)
    }px, ${max}px)`;
