import { Provider } from 'wikr-core-analytics';
import { call, put } from 'redux-saga/effects';

import api from 'api';

import { updateUserError, updateUserRequest, updateUserSuccess } from 'store/user/actions';
import { notifyError, notifySuccess } from 'store/notifications/actions';

import { getBirthdayByAge } from 'helpers/date';

import { UserResponse } from 'types/apiInterfaces/user';

export function* updateUser({ payload: { payload, onSuccess, onError } }: ReturnType<typeof updateUserRequest>) {
    try {
        const { age, ...restUserData } = payload;

        const userData: UserResponse = yield call(api.user.update, {
            ...restUserData,
            date_of_birth: age && getBirthdayByAge(age),
        });

        Provider.setUserId(userData.user_id);
        yield put(updateUserSuccess(userData));
        yield put(notifySuccess({ message: 'message.success.changesSaved' }));

        if (onSuccess) yield call(onSuccess);
    } catch (error: any) {
        yield put(updateUserError({ error: error.message }));
        yield put(notifyError({ message: 'message.error.changesNotSaved' }));

        if (onError) yield call(onError);
    }
}
