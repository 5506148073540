import { all, takeLatest } from 'redux-saga/effects';

import { updateUserEmail } from './updateUserEmail';
import { updateUser } from './updateUser';
import { getFeatures } from './getFeatures';
import { getCurrentUser } from './getCurrentUser';
import * as actionTypes from '../actionTypes';

export default function* watchUser() {
    yield all([
        takeLatest(actionTypes.GET_CURRENT_USER_REQUEST, getCurrentUser),
        takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser),
        takeLatest(actionTypes.UPDATE_USER_EMAIL, updateUserEmail),
        takeLatest(actionTypes.GET_USER_FEATURES, getFeatures),
    ]);
}
