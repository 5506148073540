import { call, delay, put } from 'redux-saga/effects';

import api from 'api';

import { updateUserEmail as updateUserEmailAction, updateUserError, updateUserSuccess } from 'store/user/actions';
import { notifyError, notifySuccess } from 'store/notifications/actions';

import { UserResponse } from 'types/apiInterfaces/user';

export function* updateUserEmail({ payload: { email, onSuccess, onError } }: ReturnType<typeof updateUserEmailAction>) {
    try {
        const userData: UserResponse = yield call(api.user.update, { email });

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess({ message: 'change.email.success' }));

        // Fix closing notification on redirect
        yield delay(500);

        if (onSuccess) yield call(onSuccess);
    } catch (error: any) {
        yield put(updateUserError({ error: error.message }));
        yield put(notifyError({ message: 'change.email.error' }));

        if (onError) yield call(onError);
    }
}
