export const GET_COMPLETED_PLAN_STATISTICS = 'PERSONAL_PLAN/GET_COMPLETED_PLAN_STATISTICS';
export const GET_COMPLETED_PLAN_STATISTICS_SUCCESS = 'PERSONAL_PLAN/GET_COMPLETED_PLAN_STATISTICS_SUCCESS';

export const GET_WORKOUT_SUCCESS = 'PERSONAL_PLAN/GET_WORKOUT_SUCCESS';

export const GET_CURRENT_PLAN = 'PERSONAL_PLAN/GET_CURRENT_PLAN';
export const GET_CURRENT_PLAN_SUCCESS = 'PERSONAL_PLAN/GET_CURRENT_PLAN_SUCCESS';

export const GENERATE_PLAN = 'PERSONAL_PLAN/GENERATE_PLAN';

export const COMPLETE_WORKOUT = 'PERSONAL_PLAN/COMPLETE_WORKOUT';

export const RESET_WORKOUT = 'PERSONAL_PLAN/RESET_WORKOUT';

export const SET_CURRENT_PLAN_COMPLETION = 'PERSONAL_PLAN/SET_CURRENT_PLAN_COMPLETION';

export const SET_LAST_PLAN_DAY_HATCH = 'PERSONAL_PLAN/SET_LAST_PLAN_DAY_HATCH';

export const SET_COMPLETE_PLAN_MODAL_VISIBILITY = 'PERSONAL_PLAN/SET_COMPLETE_PLAN_MODAL_VISIBILITY';
