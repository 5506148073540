export const GET_CURRENT_USER_REQUEST = 'USER/GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'USER/GET_CURRENT_USER_SUCCESS';

export const UPDATE_USER = 'USER/UPDATE_USER';
export const UPDATE_USER_REQUEST = 'USER/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'USER/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'USER/UPDATE_USER_ERROR';

export const UPDATE_USER_EMAIL = 'USER/UPDATE_USER_EMAIL';

export const RESET_USER_DATA = 'USER/RESET_USER_DATA';

export const GET_USER_FEATURES = 'USER/GET_USER_FEATURES';
export const SET_USER_FEATURES = 'USER/SET_USER_FEATURES';
