/* eslint-disable max-lines */
import { useCallback } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import {
    cancelSubscription,
    requestCancelSubscriptionWithOffer,
    fetchDiscountSubscription,
    pauseSubscription,
    resumeSubscription,
    setCancellationCandidates,
    setReminder,
    updateSubscription,
    requestResetSubscription,
} from 'store/subscriptions/actions';

import { DISCOUNT_TYPES, SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { ActiveGroup, Experiment } from 'services/Firebase/utils/experiments';
import useVariant from 'services/Firebase/hooks/useVariant';
import { sendAnalyticCancelSubscriptionClick } from 'services/analytics';

import { useDispatch, useModals, useSelector } from 'hooks';

import { getActiveSubscriptionIds, getSubscriptionIdsWithoutReminder } from './helpers';

import { ModalName } from 'components/Modals/types';

import { CancelFlowOffers, ISubscription, SubscriptionPauseType } from 'types/subscription';

const useCancelSubscriptionFlow = () => {
    const dispatch = useDispatch();

    const { cancellationCandidates, discountSubscription } = useSelector(selectSubscriptions);

    const variant = useVariant(Experiment.WinbackFlow);

    const { openModal } = useModals();

    const initSubscriptionCancellation = useCallback(
        (candidate: ISubscription[]) => {
            const { external_id, state, pause_type, is_charge_reminder_on, discount_type } = candidate[0];

            const isDiscountOfferAvailable = is_charge_reminder_on || pause_type || discount_type;

            const nonDiscountOfferModal =
                variant === ActiveGroup.A ? ModalName.CancellationReasonModal : ModalName.ConfirmationModal;

            openModal(isDiscountOfferAvailable ? ModalName.ConfirmationModalWithDiscount : nonDiscountOfferModal);

            dispatch(setCancellationCandidates(candidate));
            sendAnalyticCancelSubscriptionClick({
                external_id,
                sub_status: state,
                free_month: pause_type === SubscriptionPauseType.FreeMonth,
                reminder: is_charge_reminder_on,
                ...(candidate.length > 1 && { event_action: 'cancel_all_subscriptions' }),
            });
        },
        [dispatch, openModal, variant]
    );

    const onCancelSubscription = useCallback(() => {
        if (!cancellationCandidates) return;

        dispatch(cancelSubscription(cancellationCandidates));
    }, [cancellationCandidates, dispatch]);

    const onChangeSubscription = useCallback(
        (isExtraOffer = false) => {
            if (!discountSubscription || !cancellationCandidates) return;

            dispatch(
                updateSubscription({
                    product: discountSubscription,
                    external_id: cancellationCandidates[0].external_id,
                    discount_type: isExtraOffer ? DISCOUNT_TYPES.extra : DISCOUNT_TYPES.base,
                })
            );
        },
        [discountSubscription, dispatch, cancellationCandidates]
    );

    const onResetSubscription = useCallback(
        (isExtraOffer: boolean) => {
            if (!discountSubscription || !cancellationCandidates) return;

            dispatch(requestResetSubscription(isExtraOffer));
        },
        [discountSubscription, dispatch, cancellationCandidates]
    );

    const onResumeSubscription = useCallback(
        (external_id: string, date: string) => {
            dispatch(resumeSubscription({ external_id, date }));

            sendAnalyticCancelSubscriptionClick({
                external_id,
                sub_status: SUBSCRIPTION_STATES.Paused,
                event_action: CancelFlowOffers.Resume,
            });
        },
        [dispatch]
    );

    const onRequestPause = useCallback(
        (pauseType: SubscriptionPauseType) => {
            dispatch(
                pauseSubscription({
                    pause_type: pauseType,
                    externalIds: getActiveSubscriptionIds(cancellationCandidates ?? []),
                })
            );
        },
        [dispatch, cancellationCandidates]
    );

    const onRequestSetReminder = useCallback(() => {
        dispatch(
            setReminder({
                externalIds: getSubscriptionIdsWithoutReminder(cancellationCandidates ?? []),
            })
        );
    }, [dispatch, cancellationCandidates]);

    const getDiscountSubscription = useCallback(
        (isExtraOffer = false) => {
            if (!cancellationCandidates) return;

            const { external_id } = cancellationCandidates[0];

            dispatch(
                fetchDiscountSubscription({
                    discount_type: isExtraOffer ? DISCOUNT_TYPES.extra : DISCOUNT_TYPES.base,
                    external_id,
                })
            );
        },
        [cancellationCandidates, dispatch]
    );

    const onCancelSubscriptionWithOffer = useCallback(() => {
        if (!cancellationCandidates) return;

        dispatch(requestCancelSubscriptionWithOffer(cancellationCandidates));
    }, [cancellationCandidates, dispatch]);

    return {
        initSubscriptionCancellation,
        cancellationCandidates,
        onCancelSubscription,
        onChangeSubscription,
        discountSubscription,
        onResumeSubscription,
        onRequestSetReminder,
        onRequestPause,
        getDiscountSubscription,
        onCancelSubscriptionWithOffer,
        onResetSubscription,
    };
};

export default useCancelSubscriptionFlow;
