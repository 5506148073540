import { Provider } from 'wikr-core-analytics';
import { call, put } from 'redux-saga/effects';

import { setToken } from 'api/utils/tokenManagement';
import api from 'api';

import { getCurrentUserSuccess, getUserFeatures } from 'store/user/actions';
import { notifyError } from 'store/notifications/actions';

import { authenticationBySignature, setAuthenticationStatus, setAuthRedirectUrl } from '../actions';

import { getParamFromUrl } from 'helpers/utils';
import { userAPIResponseTransformer } from 'helpers/api';

import { UserResponse } from 'types/apiInterfaces/user';

export function* authenticateBySignatureSaga({ payload }: ReturnType<typeof authenticationBySignature>) {
    const { uId, signature, onSuccess, onError } = payload;
    const utmSource = getParamFromUrl('utm_source') || 'default_device';

    localStorage.setItem('utmSource', utmSource);
    Provider.setUTMSource(utmSource);

    try {
        const userData: UserResponse = yield call(api.user.getUserBySignature, { uId, signature });

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        yield put(setAuthenticationStatus(true));

        Provider.setUserId(userData.user_id);

        if (userData?.token) {
            yield call(setToken, userData.token);
        }

        if (onSuccess) {
            yield call(onSuccess);
        }

        yield put(getUserFeatures());

        yield put(setAuthRedirectUrl(null));
    } catch (error: any) {
        yield put(notifyError({ message: 'message.error.somethingWentWrong' }));
        yield put(setAuthenticationStatus(false));

        if (onError) {
            yield call(onError, error);
        }
    }
}
