import { call, put } from 'redux-saga/effects';

import api from 'api';

import { setUserFeatures } from 'store/user/actions';

import { UserFeature } from 'types/apiInterfaces/user';

export function* getFeatures() {
    try {
        const { features } = yield call(api.user.getFeatures);

        const webAvailableFeatures = features.filter((feature: UserFeature) => feature !== UserFeature.AllGuides);

        yield put(setUserFeatures(webAvailableFeatures));
    } catch (error) {
        console.error(error);
    }
}
