export default {
    faviconUrl: '/favicons/favicon.png',
    logoUrl: '/logos/logo.png',
    inline: {
        '--surface-main': '#ff4f64',
        '--brand-primary': '#F94E50',
        '--brand-secondary': '#4E69F9',
        '--brand-primary-light': '#4E69F9',
        '--some': '#000',
    },
    theme: {
        'primary-default': '#FCC419',
        'primary-hovered': '#EEBD29',
        'primary-active': '#FDCA2D',
        'primary-disabled': '#F8D97B',

        'secondary-default': '#4AA987',
        'secondary-hovered': '#69C6A4',
        'secondary-active': '#429D7C',
        'secondary-disabled': '#9FCFBE',

        'on-primary': '#212121',

        'text-main': '#212121',
        'text-secondary': '#424242',
        'text-secondary-subdued': '#878E95',
        'border-default': '#CBCBDC',
        'border-subdued': '#E3E3E9',

        'surface-main': '#F3F3F8',
        'surface-secondary': '#E0E0EA',
        'surface-default': '#FFFFFF',
        'surface-hovered': '#F8EEE0',
        'surface-disabled': '#FEFEFF',
        'surface-selected': '#FFFFFF',

        'success-default': '#2E9C42',
        'success-highlight': '#7FC898',
        'success-surface': '#CFDFD1',

        'warning-default': '#CB9038',
        'warning-highlight': '#F8C984',
        'warning-surface': '#F3EBD4',

        'critical-default': '#D75353',
        'critical-highlight': '#ECA2A2',
        'critical-surface': '#EED6D6',

        rating: '#000',
        discount: '#443AC0',
        safe: '#7249C9',
        'safe-surface': '#D4E4F3',

        'action-main': '#575757',
        'action-secondary': '',
        'action-hovered': '',
        'action-pressed': '',
        'action-disabled': '#BFBCBC',

        'default-indent': '16',
        'border-radius-small': '8',
        'border-radius-medium': '12',
        'default-box-shadow': '0 8px 16px rgba(238, 143, 125, 0.25)',

        'info-default': '#1975FF',
        'info-surface': '#E0E9FF',

        'decorative-a': '#D75353',
        'decorative-b': '#4AA987',
        'decorative-c': '#FFFFFF',
        'decorative-d': '#7249C9',
        'decorative-e': '#F2F4F6',
        'decorative-f': '#F8EEE0',

        'default-grid-gutter': '20',
    },
};
