import { Text, Button, Box } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';

import { CANCELLATION_REASONS_MODAL_ID } from 'constants/analytics';
import { reasons } from './constants';

import { ActiveGroup, Experiment } from 'services/Firebase/utils/experiments';
import useVariant from 'services/Firebase/hooks/useVariant';
import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticTakeQuizClick } from 'services/analytics';

import { useCancelSubscriptionFlow, useModals } from 'hooks';
import useCancellationReasonSubmit from './hooks/useCancellationReasonSubmit';
import { useGetTexts } from '../ConfirmationModal/hooks/useGetTexts';

import { getValuesByKey } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import HeaderWithLogo from 'components/ModalHeaders/HeaderWithLogo';
import Modal from 'components/Modal';

import * as S from './styled';

import { IReason } from './types';

const CancellationReasonModal = () => {
    const { t } = useTranslation();
    const { onSubmitSurvey } = useCancellationReasonSubmit();
    const { onCancelSubscription, cancellationCandidates } = useCancelSubscriptionFlow();
    const { openModal, closeModal } = useModals();
    const subscription_id = useMemo(
        () => getValuesByKey(cancellationCandidates, 'external_id'),
        [cancellationCandidates]
    );

    const variant = useVariant(Experiment.WinbackFlow);

    const isExperimentActive = variant === ActiveGroup.A;

    const [selectedReason, setReason] = useState<IReason | null>(null);

    useEffect(() => {
        trackScreenLoad(CANCELLATION_REASONS_MODAL_ID, { subscription_id });
    }, [subscription_id]);

    const submitSurvey = () => {
        if (!selectedReason) return;

        onSubmitSurvey({
            reason: selectedReason,
        });
    };

    const handleSelectReason = (reason: IReason) => () => {
        setReason(reason);
        sendAnalyticTakeQuizClick();
    };

    const onBackButtonClick = () => {
        isExperimentActive ? closeModal() : openModal(ModalName.ConfirmationModal);
    };

    const isMultiSubs = cancellationCandidates && cancellationCandidates.length > 1;

    const { title, subtitle } = useGetTexts({
        titleText: isMultiSubs
            ? 'subscription.confirmationModal.title.abtest.multi'
            : 'subscription.confirmationModal.title.abtest.single',
        subtitleText: isMultiSubs
            ? 'subscription.confirmationModal.subtitle.abtest.multi'
            : 'subscription.confirmationModal.subtitle.abtest.single',
    });

    return (
        <Modal customStyles={{ padding: 0 }} isOpen fullscreen dataLocator="reasonModal" withRoundedCorners={false}>
            <HeaderWithLogo onBackButtonClick={onBackButtonClick} />
            <Box paddingTop={24} paddingBottom={16} className="container-sm">
                <Text
                    type="h5"
                    mb={20}
                    text={t(isExperimentActive ? title : 'subscription.cancellationReason.modal.title')}
                />
                {isExperimentActive && <Text color="text-secondary" text={subtitle} mb={32} />}

                <Box mb={32}>
                    {reasons.map((reason) => {
                        const { title, value } = reason;

                        return (
                            <S.ReasonItem
                                key={value}
                                onClick={handleSelectReason(reason)}
                                isSelected={value === selectedReason?.value}
                                data-locator="option"
                            >
                                <Text type="medium-text" bold text={t(title)} />
                            </S.ReasonItem>
                        );
                    })}
                </Box>

                <Button
                    onClick={submitSurvey}
                    dataLocator="continue"
                    disabled={Boolean(!selectedReason)}
                    mb={16}
                    text={t('basics.continue')}
                />

                <OutlinedButton
                    dataLocator="cancel"
                    text={t('basics.cancelBtn.cancelAnyway')}
                    onClick={onCancelSubscription}
                    backgroundColor="surface-main"
                />
            </Box>
        </Modal>
    );
};

export default CancellationReasonModal;
