import { Box } from 'wikr-core-components';
import styled from 'styled-components';

interface SkeletonProps {
    width?: number;
    height?: number;
    borderRadius?: number;
    marginLeft?: number;
}

export const SkeletonContainer = styled(Box).attrs(({ borderRadius, marginLeft }: SkeletonProps) => ({
    borderRadius,
    marginLeft,
}))<SkeletonProps>`
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : '100%')};
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : 'auto')};
    padding-bottom: ${({ height }) => (typeof height === 'number' ? `${height}px` : '100%')};
    margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
    overflow: hidden;
    position: relative;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
